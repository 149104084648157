import { IListOcorrencia, IResponseOcorrencia } from '../../dtos/Ocorrencia';
import { Icon, IconBlock, ParagphElement, Title } from './styles';
import React, { useCallback, useEffect, useState } from 'react';
import { getOcorrenciasByIdReclamante, reclamanteLogout } from '../../api/services/Reclamante';

import { FrameButton } from '../../components/FrameButton';
import { FrameData } from '../../components/FrameData';
import { FrameTable } from '../../components/FrameTable';
import { IReclamante } from '../../dtos/Reclamante';
import { Loading } from '../../components/Loading';
import { RouteComponentProps } from 'react-router-dom';
import { Row } from '../../components/Row';
import { prefix } from '../../router';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useToast } from '../../hooks/toast';

const IframeHome = ({ history }: RouteComponentProps) => {
  const toast = useToast();
  const [accessToken, setAccessToken] = useLocalStorage<string | undefined>('accessToken', '');
  const [refreshToken, setRefreshToken] = useLocalStorage<string | undefined>('refreshToken', '');
  const [codParqueServico] = useLocalStorage<number>('cod_parque_servico', -1);
  const [dadosReclamante, setDadosReclamante] = useLocalStorage<IReclamante>('reclamante', {});
  const [ocorrencias, setOcorrencias] = useState<IListOcorrencia>();
  const [loadingPage, setLoading] = useState(true);

  const loadOcorrenciasReclamante = useCallback(async () => {
    const response = await getOcorrenciasByIdReclamante(Number(dadosReclamante.seq_reclamante), {
      cod_parque_servico: codParqueServico,
    });
    setLoading(false);

    if (response.err || response.resp.err) {
      toast.addToast({
        type: 'warn',
        description: 'Ocorreu um erro ao carregar as ocorrencias do usuario!',
      });
      return;
    }

    const ocorrenciasLocal = response.resp.map((ocorrencia: IResponseOcorrencia) => {
      let dth;

      if (ocorrencia.dth_reclamacao) {
        const date = new Date(ocorrencia.dth_reclamacao);
        [dth] = date.toLocaleString().split(' ');
      }

      return { ...ocorrencia, dth_reclamacao: dth };
    });

    setOcorrencias(ocorrenciasLocal);
  }, [toast, dadosReclamante]);

  function handleRedirectToGenerate() {
    history.push('generate');
  }

  function handleRedirectToRating(item: IResponseOcorrencia) {
    history.push({
      pathname: '/home/rating',
      search: `?code=${item.seq_atendimento_ponto_servico}`,
    });
  }

  function handleLogout() {
    setAccessToken('');
    setRefreshToken('');
    setDadosReclamante({});

    history.push(`${prefix}/`);

    async function fetchData() {
      await reclamanteLogout(accessToken);
    }
    fetchData();
  }

  const handleIconBlock = useCallback(() => {
    toast.addToast({
      type: 'warn',
      description: 'No momento essa ocorrência não pode ser avaliado',
    });
  }, []);

  useEffect(() => {
    if (!accessToken && !refreshToken && !dadosReclamante) {
      history.push(`${prefix}/`);
    }
  }, [accessToken, refreshToken, dadosReclamante]);

  useEffect(() => {
    loadOcorrenciasReclamante();

    return () => setOcorrencias([]);
  }, [history, loadOcorrenciasReclamante]);

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <FrameData>
      <Row>
        <Title>Ocorrência</Title>
      </Row>
      <Row>
        <ParagphElement marginBottom={30} marginTop={5}>
          Navegue por suas ocorrências.
        </ParagphElement>
      </Row>

      <Row>
        <FrameTable column={10} last={1}>
          <thead>
            <tr>
              <th>Protocolo</th>
              <th>Data Solicitação</th>
              <th>Status</th>
              <th>Avaliar</th>
            </tr>
          </thead>
          <tbody>
            {ocorrencias &&
              ocorrencias.map((ocorrencia, index) => (
                <tr key={`${ocorrencia.dth_reclamacao}-${index}`}>
                  <td>{ocorrencia.num_protocolo}</td>
                  <td>{ocorrencia.dth_reclamacao}</td>
                  <td>{ocorrencia.nom_status_ocorrencia}</td>
                  <td>
                    {ocorrencia.seq_atendimento_ponto_servico ? (
                      <Icon onClick={() => handleRedirectToRating(ocorrencia)} />
                    ) : (
                      <IconBlock onClick={() => handleIconBlock()} />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </FrameTable>
      </Row>

      <Row>
        <FrameButton onClick={handleLogout} size={49}>
          Sair
        </FrameButton>
        <FrameButton onClick={handleRedirectToGenerate} size={49}>
          Criar uma ocorrência
        </FrameButton>
      </Row>
    </FrameData>
  );
};

export default IframeHome;
