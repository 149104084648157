import styled, { createGlobalStyle } from 'styled-components';

export interface IContainer {
  height?: number;
  width?: number;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  margin?: string;
  resize?: 'horizontal' | 'vertical' | 'both';
  positionResize?: 'left' | 'right';
}

export const Container = styled.div<IContainer>`
  position: relative;
  width: 100%;
  margin: ${(props) => props.margin};
  direction: ${(props) => {
    if (props.positionResize === 'left') return 'rtl';
    return 'ltr';
  }};

  .leaflet-container {
    resize: ${(props) => props.resize};

    height: calc(${(props) => props.height || 80}vh - 60px);
    width: 100%;

    min-height: ${(props) => props.minHeight || 100}px;
    min-width: ${(props) => props.minWidth || 100}px;

    ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth}px` : '')};
    ${(props) => (props.maxHeight ? `max-height: ${props.maxHeight}px` : '')};
  }

  .leaflet-control-attribution.leaflet-control {
    display: none !important;
  }

  .pulse_icon_personalized {
    display: block;
    border-radius: 50%;
    cursor: pointer;
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 0 0;
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

export const GlobalMapStyle = createGlobalStyle`
  .leaflet-control-zoom a {
    background-color: white;
    border: 0;
  }

  .leaflet-control-zoom a:hover{
    background-color: rgba(0, 0, 0, 0.1);
    border: 0;
  }

  .leaflet-control-zoom.leaflet-bar.leaflet-control {
    border: 0;
    background-color: white;
    padding: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }

  .leaflet-draw-toolbar.leaflet-bar {
    border: 0;
    background-color: white;
    padding: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }

  .leaflet-draw-toolbar.leaflet-bar a {
    border: 0;
  }
`;
