import React from 'react';
import { FrameData } from '../FrameData';

import { Container, Loader } from './styles';

export const Loading = () => {
  return (
    <FrameData>
      <div style={{ height: '520px' }}>
        <Container>
          <Loader>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </Loader>
        </Container>
      </div>
    </FrameData>
  );
};

export default Loading;
