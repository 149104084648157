import React, { Dispatch, SetStateAction, createContext, useState } from 'react';

export interface IIframeState {
  latlong?: [number, number];
  tipoBusca?: 'plaqueta' | 'endereco' | string;
  dadosCadastroOcorrencia?: {
    ocorrencia?: {
      cod_parque_servico?: number;
    };
    ocorrenciaPontoServico?: {
      cod_tipo_ocorrencia?: number;
      nom_endereco_livre?: string;
      cod_ponto_servico?: number;
      cod_bairro?: number;
      cod_municipio?: number;
      dsc_observacao?: string;
      json_ocorrencia_ponto_servico?: {
        coords?: {
          latitude: number;
          longitude: number;
        };

        endereco?: any;
        imagens?: { nom_caminho_imagem: string | null }[];
      };
    };
  };
}

export interface IIframeContext {
  iframeState?: IIframeState;
  setIframeState?: Dispatch<SetStateAction<IIframeState>>;
}

interface IIframeProvider {
  children?: React.ReactNode;
}

export const IframeContext = createContext<IIframeContext>({} as IIframeContext);

export const IframeProvider = ({ children }: IIframeProvider) => {
  const [iframeState, setIframeState] = useState<IIframeState>({});

  return <IframeContext.Provider value={{ iframeState, setIframeState }}>{children}</IframeContext.Provider>;
};

export default IframeContext;
