import { FrameAutoComplete, IEvent } from '../../components/FrameAutoComplete';
import { IMapContainer, Map, Marker } from '../../components/Map';
import { ParagphElement, Title } from './styles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getAdressByLatLong, getLatLonByUFAndCity } from '../../api/services/nominatim';
import { getMunicipiosById, getUfById } from '../../api/services/Localidade';
import iframeContext, { IIframeContext } from '../../contexts/IframeContext';

import { FrameButton } from '../../components/FrameButton';
import { FrameData } from '../../components/FrameData';
import { IReclamante } from '../../dtos/Reclamante';
import Loading from '../../components/Loading';
import { RouteComponentProps } from 'react-router-dom';
import { Row } from '../../components/Row';
import debounce from 'debounce';
import { getParqueServicoById } from '../../api/services/ParqueService';
import { prefix } from '../../router';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useToast } from '../../hooks/toast';

type ILatLng = [number, number];
interface ILocalizacao {
  UF?: string;
  cidade?: string;
  rua?: string;
  lat?: number;
  lon?: number;
  cod_municipio?: number;
  cod_estado?: number;
}

const IframeAddress = ({ history }: RouteComponentProps) => {
  const [codParqueServico] = useLocalStorage<number>('cod_parque_servico', -1);
  const [dadosReclamante] = useLocalStorage<IReclamante>('reclamante', {});
  const [cityParqueServico, setCityParqueServico] = useState<string>('');
  const [markerLatlng, setMarkerLatlng] = useState<ILatLng>([0, 0]);
  const [mapLatLng, setMapLatLng] = useState<ILatLng>([0, 0]);
  const [address, setAdress] = useState<ILocalizacao>({});
  const { setIframeState, iframeState } = useContext<IIframeContext>(iframeContext);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [searchInputItem, setSearchInputItem] = useState<any[]>([]);
  const mapElement = useRef<IMapContainer>();
  const toast = useToast();

  function serializeString(str: string) {
    if (!str) return '';

    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  async function loadUfById(idUf: number) {
    const response = await getUfById(idUf);

    if (response.resp) {
      const { nom_uf: UF } = response.resp;

      setAdress((oldValue) => ({ ...oldValue, UF }));
    }
  }

  const loadMunicipioById = useCallback(async (idMunicipio: number) => {
    const response = await getMunicipiosById(idMunicipio);

    if (response.resp) {
      const { cod_estado: codEstado, nom_nome: cidade } = response.resp;

      setAdress((oldValue) => ({ ...oldValue, cidade, cod_estado: codEstado }));

      loadUfById(codEstado);
    }
  }, []);

  const getMapPosition = useCallback(async () => {
    let lat: number;
    let long: number;

    if (codParqueServico !== -1 && codParqueServico) {
      const parqueServico = await getParqueServicoById(codParqueServico);
      setLoadingPage(false);

      if (parqueServico.resp) {
        const { lat: latLocal, lng } = parqueServico.resp.area_json.center;
        const { cod_municipio: codMunicipio, nom_parque_servico: cidadeParqueServico } = parqueServico.resp;

        setCityParqueServico(cidadeParqueServico);

        setAdress((oldValue) => ({
          ...oldValue,
          cod_municipio: codMunicipio,
        }));

        lat = lng;
        long = latLocal;

        if (lat && long) {
          setMapLatLng([lat, long]);

          if (setIframeState) {
            setIframeState((oldValue) => ({
              ...oldValue,
              latlong: [lat, long],
            }));
          }

          loadMunicipioById(codMunicipio);

          return;
        }
      }
    }

    setLoadingPage(false);

    navigator.geolocation.getCurrentPosition((position) => {
      lat = position.coords.latitude;
      long = position.coords.longitude;

      if (!lat && !long) return;

      setMapLatLng([lat, long]);
      setMarkerLatlng([lat, long]);

      if (setIframeState) {
        setIframeState((oldValue) => ({
          ...oldValue,
          latlong: [lat, long],
        }));
      }
    });
  }, [setIframeState, loadMunicipioById]);

  function handleMapClicked({ latlng }: any) {
    setMarkerLatlng([latlng.lat, latlng.lng]);

    if (setIframeState) {
      setIframeState((oldValue) => ({
        ...oldValue,
        latlong: [latlng.lat, latlng.lng],
      }));
    }
  }

  async function handleSearch(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!address.rua) {
      toast.addToast({ type: 'warn', description: 'Digite um local valido!' });
      return;
    } //
    if (!address.cidade && address.rua) {
      toast.addToast({
        type: 'warn',
        description: 'Ocorreu um erro ao buscar a localizacao do parque de serviço, por favor recarregue a página!',
      });
      return;
    }

    let data;

    if (address.lat && address.lon) {
      data = address;
    } //
    else {
      const response = await getLatLonByUFAndCity(address.UF || '', address.cidade || '', address.rua);

      if (response.failed) {
        toast.addToast({ type: 'warn', description: response.message });
        return;
      }

      if (!response.data.length) {
        toast.addToast({ type: 'warn', description: 'Nenhum local encontrado!' });
        return;
      }

      [data] = response.data;
    }

    const markerAddress = await searchAddressByCoordinates(data.lat, data.lon);

    if (!markerAddress) {
      toast.addToast({ description: 'Local selecionado invalido!', type: 'warn' });
      return;
    }

    const { city: cidadeMarkerSelecionado } = markerAddress.data.address;

    if (serializeString(cidadeMarkerSelecionado) !== serializeString(cityParqueServico)) {
      toast.addToast({ description: 'O local da busca não pertence ao parque de serviço atual!', type: 'warn' });
      return;
    }

    setMapLatLng([data.lat, data.lon]);
    handleMapClicked({
      latlng: {
        lat: data.lat,
        lng: data.lon,
      },
    });

    if (mapElement.current) {
      mapElement.current.leafletElement.setView([data.lat, data.lon], 15);
    }
  }

  function handleRedirectGenerateOcorrencia() {
    if (setIframeState) setIframeState((oldValue) => ({ ...oldValue, tipoBusca: '' }));
    history.push(`${prefix}/home`);
  }

  async function searchAddressByCoordinates(lat: number, long: number) {
    const response = await getAdressByLatLong(lat, long);

    if (response.failed) {
      toast.addToast({
        type: 'warn',
        description:
          'Não foi possivel encontrar um endereco vinculado a essa localização, tente marcar o ponto em outro local!',
      });
      return;
    }

    return response;
  }

  async function handleRedirectToSendPage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const [lat, long] = markerLatlng || [null, null];

    if (!dadosReclamante || !codParqueServico) handleRedirectGenerateOcorrencia();

    if (!lat && !long) {
      toast.addToast({
        type: 'warn',
        description: 'Selecione o local da ocorrencia no mapa',
      });
      return;
    }

    const markerAddress = await searchAddressByCoordinates(lat, long);

    if (!markerAddress) {
      toast.addToast({ description: 'Local selecionado invalido!', type: 'warn' });
      return;
    }

    const { road: nom_endereco_livre, city: cidadeMarkerSelecionado } = markerAddress.data.address;

    if (serializeString(cidadeMarkerSelecionado) !== serializeString(cityParqueServico)) {
      toast.addToast({ description: 'O local selecionado não pertence ao parque de serviço atual!', type: 'warn' });
      return;
    }

    const { cod_municipio } = address;

    const dadosCadastroOcorrencia = {
      ocorrencia: {
        cod_parque_servico: codParqueServico,
        cod_municipio,
      },
      ocorrenciaPontoServico: {
        nom_endereco_livre,
        cod_municipio,
        json_ocorrencia_ponto_servico: {
          coords: {
            latitude: lat,
            longitude: long,
          },

          endereco: {},
          imagens: [{ nom_caminho_imagem: null }],
        },
      },
    };

    if (setIframeState) {
      setIframeState((oldValue) => ({ ...oldValue, dadosCadastroOcorrencia }));

      history.push(`${prefix}/generate/address/send`);
    }
  }

  async function searchAdress(data: IEvent) {
    if (!data.text) {
      setSearchInputItem([]);
      return;
    }

    if (data.type === 'typing') {
      setAdress((oldValue) => ({
        ...oldValue,
        rua: data.text,
        lat: undefined,
        lon: undefined,
      }));

      const response = await getLatLonByUFAndCity(address.UF || '', address.cidade || '', data.text);

      if (response.failed) {
        toast.addToast({ type: 'warn', description: response.message });
        return;
      }

      const dataBusca = response.data;

      setSearchInputItem(dataBusca);
    } //
    else if (data.type === 'click' && data.item) {
      setAdress((oldValue) => ({
        ...oldValue,
        lat: data.item.lat,
        lon: data.item.lon,
      }));
    }
  }

  const handleInputTyping = debounce(searchAdress, 1000);

  useEffect(() => {
    if (!iframeState || !iframeState.tipoBusca) {
      history.push(`${prefix}/generate`);
    }

    getMapPosition();

    return () => {
      if (setIframeState) {
        setIframeState((oldValue) => ({ ...oldValue }));
      }

      setAdress((oldValue) => ({ ...oldValue }));
    };
  }, []);

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <FrameData>
      <Row>
        <Title>Geração de Ocorrência</Title>
      </Row>
      <Row>
        <ParagphElement marginBottom={30} marginTop={5}>
          Arraste o mapa para a posição desejada, em seguida confirme para prosseguir.
        </ParagphElement>
      </Row>

      <form onSubmit={handleSearch}>
        <Row>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              marginRight: '2%',
              width: '100%',
            }}
          >
            <FrameAutoComplete
              size={100}
              label="Endereço"
              itemText="display_name"
              onChange={handleInputTyping}
              items={searchInputItem}
            />
          </div>
          <FrameButton type="submit" size={29}>
            Buscar
          </FrameButton>
        </Row>
      </form>

      <div style={{ marginBottom: '10px' }} />

      <Row>
        {mapLatLng && (
          <Map
            scrollWheelZoom
            doubleClickZoom={false}
            onClick={handleMapClicked}
            zoom={15}
            coords={mapLatLng}
            height={50}
            refMapElement={mapElement}
            maxZoom={30}
          >
            {markerLatlng && <Marker coords={markerLatlng} />}
          </Map>
        )}
      </Row>
      <form onSubmit={handleRedirectToSendPage}>
        <Row>
          <FrameButton onClick={handleRedirectGenerateOcorrencia} size={49}>
            Cancelar
          </FrameButton>
          <FrameButton type="submit" size={49}>
            Continuar
          </FrameButton>
        </Row>
      </form>
    </FrameData>
  );
};

export default IframeAddress;
