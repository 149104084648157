import React, { useContext } from 'react';
import { FrameData } from '../../components/FrameData';
import { FrameButton } from '../../components/FrameButton';
import { Row } from '../../components/Row';
import iframeContext, { IIframeContext } from '../../contexts/IframeContext';
import { useToast } from '../../hooks/toast';
import { RouteComponentProps } from 'react-router-dom';
import { prefix } from '../../router';
import { Title, ParagphElement, RadioSearchContainer } from './styles';

const IframeGenerate = ({ history }: RouteComponentProps) => {
  const toast = useToast();
  const { iframeState, setIframeState } = useContext<IIframeContext>(iframeContext);

  function handleSelectTipoBusca(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    if (setIframeState) {
      setIframeState((oldValue) => ({
        ...oldValue,
        tipoBusca: value,
      }));
    }
  }

  function handleRedirectToGenerateOcorrencia() {
    if (iframeState && iframeState.tipoBusca === 'plaqueta') {
      history.push(`${prefix}/generate/tag`);
    } //
    else if (iframeState && iframeState.tipoBusca === 'endereco') {
      history.push(`${prefix}/generate/address`);
    } //
    else {
      toast.addToast({
        type: 'warn',
        description: 'Tipo de busca invalido!',
      });
    }
  }

  function handleCancel() {
    history.push(`${prefix}/home`);
  }

  return (
    <FrameData>
      <Row>
        <Title>Geração de Ocorrência</Title>
      </Row>

      <Row>
        <ParagphElement marginBottom={30} marginTop={5}>
          Caso não possua a etiqueta do poste, utiize o campo de endereço para prosseguir com a arbetura do chamado.
        </ParagphElement>
      </Row>

      <Row>
        <RadioSearchContainer>
          <input
            checked={iframeState && iframeState.tipoBusca === 'plaqueta'}
            id="busca-plaqueta"
            name="tipo-busca"
            type="radio"
            value="plaqueta"
            onChange={handleSelectTipoBusca}
          />{' '}
          <label htmlFor="busca-plaqueta">Busca por plaqueta (Número presente no próprio ponto)</label>
        </RadioSearchContainer>
      </Row>

      <Row>
        <RadioSearchContainer>
          <input
            checked={iframeState && iframeState.tipoBusca === 'endereco'}
            id="busca-endereco"
            name="tipo-busca"
            type="radio"
            value="endereco"
            onChange={handleSelectTipoBusca}
          />{' '}
          <label htmlFor="busca-endereco">Busca por endereço (Endereço próximo ao ponto)</label>
        </RadioSearchContainer>
      </Row>

      <Row>
        <FrameButton onClick={handleCancel} size={49}>
          Cancelar
        </FrameButton>
        <FrameButton onClick={handleRedirectToGenerateOcorrencia} size={49}>
          Continuar
        </FrameButton>
      </Row>
    </FrameData>
  );
};

export default IframeGenerate;
