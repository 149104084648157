import { Container, ContainerSelect, Label } from './style';

import React from 'react';

interface ISelect {
  label: string | number;
  value?: any;
  handleFunction?: any;
  size?: number;
  margin?: string;
  disabled?: boolean;
  name?: string;
  required?: boolean;
  children?: React.ReactNode;
  removeDefaultOption?: boolean;
  defaultValue?: string;
  defaultMessage?: string;
}

export const FrameSelect = ({
  label,
  value,
  handleFunction,
  size,
  children,
  disabled,
  margin,
  name,
  required,
  removeDefaultOption,
  defaultValue,
  defaultMessage,
}: ISelect) => {
  return (
    <Container margin={margin} size={size}>
      <Label>{label}</Label>

      <ContainerSelect>
        <select
          required={required}
          name={name}
          onChange={handleFunction}
          disabled={disabled}
          // defaultValue="null"
          value={value}
          defaultValue={defaultValue || 'DEFAULT'}
        >
          {/* <option value="null">Selecione</option> */}
          {!removeDefaultOption && (
            <option value={defaultValue || 'DEFAULT'}>{defaultMessage ? defaultMessage : 'Selecione'}</option>
          )}

          {children}
        </select>
      </ContainerSelect>
    </Container>
  );
};
