import { ParagphElement, Title } from './styles';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import {
  criarAvaliacao,
  criarAvaliacaoPublico,
  getAvaliacaoByAtendimento,
  getAvaliacaoByAtendimentoPublico,
  getTipoAvaliacao,
} from '../../api/services/Avaliacao';

import { FrameButton } from '../../components/FrameButton';
import { FrameData } from '../../components/FrameData';
import { FrameInput } from '../../components/FrameInput';
import { FrameSelect } from '../../components/FrameSelect';
import { ITipoAvaliacao } from '../../dtos/Avaliacao';
import Loading from '../../components/Loading';
import { Row } from '../../components/Row';
import { useToast } from '../../hooks/toast';

interface IFormData {
  num_avaliacao_sistema: number | string;
  num_avaliacao_colaborador: number | any;
  fl_prazo: boolean | string | any;
  dsc_melhoria: string | any;
}

interface IFormType {
  activeID?: boolean;
  activeToken?: boolean;
  value?: any;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const IframeRating = ({ history }: RouteComponentProps) => {
  const query = useQuery();
  const toast = useToast();

  const [formData, setFormData] = useState<IFormData>({} as IFormData);
  const [tipoAvaliacao, setTipoAvaliacao] = useState([]);
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [formType] = useState<IFormType>(() => {
    if (query.get('token')) {
      return {
        activeToken: true,
        value: query.get('token'),
      };
    }

    if (query.get('code')) {
      return {
        activeID: true,
        value: Number(query.get('code')),
      };
    }

    return {
      activeID: false,
      activeToken: false,
      value: null,
    };
  });

  const handleRedirectToHome = useCallback(() => {
    history.push('/home');
  }, [history]);

  const loadTipoAvaliacao = useCallback(async () => {
    const response = await getTipoAvaliacao();

    if (response.err) {
      toast.addToast({ type: 'warn', description: response.err });
    }

    setTipoAvaliacao(response.resp);
  }, [setTipoAvaliacao]);

  const loadAvaliacao = useCallback(async () => {
    let response: any = null;

    const { activeToken, activeID, value } = formType;
    if (activeToken) {
      response = await getAvaliacaoByAtendimentoPublico(value);
    } else if (activeID) {
      response = await getAvaliacaoByAtendimento(value);
    }

    if (response) {
      if (response.err) {
        toast.addToast({ type: 'warn', description: response.err });
      } else {
        if (response.resp) {
          const { num_avaliacao_sistema, num_avaliacao_colaborador, fl_prazo, dsc_melhoria } = response.resp;
          setFormData({
            ...formData,
            num_avaliacao_sistema,
            num_avaliacao_colaborador,
            fl_prazo,
            dsc_melhoria,
          });
        }
      }
    }
  }, [setFormData]);

  useEffect(() => {
    async function loadData() {
      await loadTipoAvaliacao();
      await loadAvaliacao();

      setLoadingPage(false);
    }

    const { activeToken, activeID } = formType;

    if (!activeToken && !activeID) {
      history.push('/');
    } else {
      loadData();
    }
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      let resp: any = null;

      const { activeToken, activeID, value } = formType;

      if (activeToken) {
        resp = await criarAvaliacaoPublico({
          ...formData,
          token: value,
        });
      } else if (activeID) {
        resp = await criarAvaliacao({
          ...formData,
          cod_atendimento: value,
        });
      }

      if (resp) {
        if (resp.err) {
          toast.addToast({ type: 'warn', description: resp.err.message || 'Houve algum erro na avaliação' });
        } else {
          toast.addToast({
            type: 'success',
            description: 'Avaliação cadastrada com sucesso!',
          });
        }
      }
    },
    [formData],
  );

  const handleOnChange = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      let updatedValue = e.currentTarget.value;

      if (updatedValue === 'true' || updatedValue == 'false' || updatedValue == 'null') {
        updatedValue = JSON.parse(updatedValue);
      }

      if (updatedValue === 'DEFAULT') {
        updatedValue = null;
      }

      setFormData({
        ...formData,
        fl_prazo: updatedValue,
      });
    },
    [formData, setFormData],
  );

  function handleSetDataInIframeState(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setFormData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  }

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <FrameData>
      <Row>
        <Title>Formulário de satisfação</Title>
      </Row>
      <Row>
        <ParagphElement marginBottom={30} marginTop={5}>
          Agradecemos pelo seu tempo, por favor responda as perguntas abaixo:
        </ParagphElement>
      </Row>

      <form onSubmit={handleSubmit}>
        <Row>
          <FrameSelect
            handleFunction={handleSetDataInIframeState}
            value={formData.num_avaliacao_sistema}
            label="Como o Sr.(a) avalia o sistema que usou até a abertura da ocorrência?"
            name="num_avaliacao_sistema"
            size={100}
          >
            {tipoAvaliacao.map((tipo: ITipoAvaliacao) => (
              <option key={tipo.seq_tipo_avaliacao} value={tipo.seq_tipo_avaliacao}>
                {tipo.nom_tipo_avaliacao}
              </option>
            ))}
          </FrameSelect>
        </Row>
        <Row>
          <FrameSelect
            handleFunction={handleSetDataInIframeState}
            value={formData.num_avaliacao_colaborador}
            label="Como o Sr.(a) avalia o atendimento prestado pelo nosso colaborador?"
            name="num_avaliacao_colaborador"
            size={100}
          >
            {tipoAvaliacao.map((tipo: ITipoAvaliacao) => (
              <option key={tipo.seq_tipo_avaliacao} value={tipo.seq_tipo_avaliacao}>
                {tipo.nom_tipo_avaliacao}
              </option>
            ))}
          </FrameSelect>
        </Row>
        <Row>
          <FrameSelect
            handleFunction={handleOnChange}
            value={formData.fl_prazo}
            label="Suas solicitações foram atendidas dentro do prazo?"
            name="fl_prazo"
            size={100}
          >
            <option value={'true'}>Sim</option>
            <option value={'false'}>Não</option>
          </FrameSelect>
        </Row>
        <Row>
          <FrameInput
            handleFunction={handleSetDataInIframeState}
            value={formData.dsc_melhoria || ''}
            label="Na sua opinião como poderiamos melhoras nosso serviço?"
            name="dsc_melhoria"
            size={100}
          />
        </Row>

        <Row>
          <FrameButton onClick={() => handleRedirectToHome()} size={49}>
            Cancelar
          </FrameButton>
          <FrameButton type="submit" size={49}>
            Enviar
          </FrameButton>
        </Row>
      </form>
    </FrameData>
  );
};

export default IframeRating;
