import styled from 'styled-components';

interface ITitle {
  weight?: string;
}

export const Title = styled.p<ITitle>`
  font-size: 24px;
  color: #42434a;
  font-weight: ${(props) => (props.weight ? props.weight : 'normal')};
`;

interface IParagphElement {
  marginBottom?: number;
  marginTop?: number;
}

export const ParagphElement = styled.p<IParagphElement>`
  color: #6e6e6e;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 5)}px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
`;

export const RadioSearchContainer = styled.div`
  color: #6e6e6e;
  align-items: center;

  & input {
    margin-right: 2px;
  }
`;
