import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  background: #ffffff;
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 100px;
  height: 100px;
  animation: animate 1s linear infinite;

  & span {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 2px;
    animation: rotate 1s linear infinite;
  }
  & span:nth-child(1) {
    top: 0;
    left: 0;
    background: #5c6bc0c5;
  }
  & span:nth-child(2) {
    top: 0;
    right: 0;
    background: #12cbc5cb;
  }
  & span:nth-child(3) {
    bottom: 0;
    left: 0;
    background: #ffa200;
  }
  & span:nth-child(4) {
    bottom: 0;
    right: 0;
    background: #00c8539d;
  }
  @keyframes animate {
    0% {
      width: 100px;
      height: 100px;
    }
    10% {
      width: 100px;
      height: 100px;
    }
    50% {
      width: 150px;
      height: 150px;
    }
    90% {
      width: 100px;
      height: 100px;
    }
    100% {
      width: 100px;
      height: 100px;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(90deg);
    }
    90% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(90deg);
    }
  }
`;
