import { IApiResponse, IFixResponse, api } from '../index';

export async function createOcorrencia(dados: any): Promise<IFixResponse> {
  const res: any = await api.post('/reclamante/ocorrencia/gerar', dados);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}

export async function findOnePontoServicoByEtiqueta(etiqueta: string): Promise<IFixResponse> {
  const res: IApiResponse = await api.get(`/reclamante/pontoservico?etiqueta=${etiqueta}`);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data: resp } = res;
  return { err: null, resp };
}
