import React from 'react';

import { Container, FrameContainer } from './style';

interface IFrameData {
  children?: React.ReactNode;
}

export const FrameData = ({ children }: IFrameData) => {
  return (
    <Container>
      <FrameContainer>{children}</FrameContainer>
    </Container>
  );
};
