import React from 'react';

import { RoundedButton, Container, IContainer, Loader, IRoundedButton } from './style';

interface IFrameButton extends IContainer, IRoundedButton {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined;
  form?: string;
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  loading?: boolean;
}

export const FrameButton = ({ children, size, onClick, form, loading, type = 'button' }: IFrameButton) => {
  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!loading && onClick) {
      onClick(event);
    }
  }

  return (
    <Container size={size}>
      <RoundedButton loadingBtn={loading} type={type} form={form} onClick={handleClick}>
        {loading ? <Loader /> : children}
      </RoundedButton>
    </Container>
  );
};
