import { IApiResponse, IFixResponse, api } from '../index';

export async function getTiposOcorrencia(): Promise<IFixResponse> {
  const res: IApiResponse = await api.get('/reclamante/ocorrencia/tipo_ocorrencia');

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}
