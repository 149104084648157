import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FrameContainer = styled.div`
  border-top-color: #ffa200;
  border-top-style: solid;
  border-top-width: 10px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 40px;
  background-color: white;
  border-radius: 20px;
  width: 90vh;

  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 6px; /*largura da barra de rolagem*/
  }
  ::-webkit-scrollbar-thumb {
    /*barra de rolagem*/
    background-color: gray;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    /*barra total*/
    background-color: transparent;
  }
`;
