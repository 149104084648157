import { ParagphElement, Title } from './styles';
import React, { useCallback, useEffect, useState } from 'react';

import { FrameButton } from '../../components/FrameButton';
import { FrameData } from '../../components/FrameData';
import { FrameInput } from '../../components/FrameInput';
import ReCAPTCHA from 'react-google-recaptcha';
import { RouteComponentProps } from 'react-router-dom';
import { Row } from '../../components/Row';
import { createReclamante } from '../../api/services/Reclamante';
import { getMunicipioByParqueServico } from '../../api/services/ParqueService';
import { prefix } from '../../router';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useToast } from '../../hooks/toast';

interface IFormData {
  cod_municipio?: string;
  nom_cpf?: string;
  nom_email?: string;
  nom_fone1?: string;
  nom_nome_completo?: string;
  nom_senha?: string;
  recaptcha?: string;
}

function validatePhoneField(field: string) {
  if (field) {
    return field.replace(/[^0-9]+/g, '');
  }
  return field;
}

const IframeAccount = ({ history }: RouteComponentProps) => {
  let captcha: any;

  const setCaptchaRef = (ref: any) => {
    if (ref) {
      captcha = ref;
    }
  };

  const [formData, setFormData] = useState<IFormData>();
  // const [, setToken] = useLocalStorage<string | undefined>('token', '');
  const [codParqueServico] = useLocalStorage<number>('cod_parque_servico', -1);
  // const [, setDadosReclamante] = useLocalStorage<IReclamante | string>('reclamante', {});
  const toast = useToast();
  let inProgress = false;

  function handleCancel() {
    history.push(`${prefix}/`);
  }

  function handleRedirectToIframeHome(dados: any) {
    // const { token, complainant: dadosReclamante } = dados;

    // setToken(token);
    // setDadosReclamante(dadosReclamante);

    handleCancel();
  }

  function handleSetFormData(event: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;

    setFormData((oldValue) => ({ ...oldValue, [name]: value }));
  }

  async function handleCreateUser(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!formData?.nom_fone1) {
      toast.addToast({ description: 'O telefone é obrigatório!', type: 'warn' });
      return;
    }

    if (inProgress) return;

    const objectUser = {
      ...formData,
      nom_fone1: validatePhoneField(formData.nom_fone1),
    };

    captcha.reset();

    inProgress = true;
    const response: any = await createReclamante(objectUser);
    inProgress = true;

    if (response.failed) {
      if (response.causes) {
        response?.causes?.forEach((cause: string) => {
          toast.addToast({ type: 'warn', description: cause });
        });
      } else {
        toast.addToast({ type: 'warn', description: response.message });
      }
      inProgress = false;
      return;
    }

    toast.addToast({
      type: 'success',
      description: 'Usuario cadastrado com sucesso!',
    });

    handleRedirectToIframeHome(response.data);
  }

  const loadParqueServicoData = useCallback(
    async (idParqueServico: string | number | any) => {
      if (!idParqueServico || idParqueServico === 'null') {
        toast.addToast({
          type: 'warn',
          description: 'Ocorreu um erro ao buscar o parque de serviço, você será redirecionado para a página inicial!',
        });

        handleCancel();
        return;
      }
      const response = await getMunicipioByParqueServico(idParqueServico);

      if (response.err) {
        toast.addToast({
          type: 'warn',
          description: response.err,
        });
      }

      setFormData((oldValue) => ({
        ...oldValue,
        cod_municipio: response.resp.cod_municipio,
      }));
    },
    [toast],
  );

  const onChangeRecaptcha = (value: any) => {
    setFormData((oldValue) => ({
      ...oldValue,
      recaptcha: value,
    }));
  };

  useEffect(() => {
    if (!codParqueServico) return;

    loadParqueServicoData(codParqueServico);

    return () => setFormData({});
  }, [codParqueServico, loadParqueServicoData]);

  return (
    <FrameData>
      <Row>
        <Title>Cadastrar Usuário</Title>
      </Row>
      <Row>
        <ParagphElement marginBottom={30} marginTop={5}>
          Informe os dados abaixo para a criação do seu usuário:
        </ParagphElement>
      </Row>

      <form onSubmit={handleCreateUser}>
        <Row>
          <FrameInput handleFunction={handleSetFormData} required label="Nome*" name="nom_nome_completo" size={100} />
        </Row>
        <Row>
          <FrameInput
            handleFunction={handleSetFormData}
            label="CPF*"
            name="nom_cpf"
            required
            type="tel"
            size={49}
            mask="999.999.999-99"
            pattern="[0-9]{3}.[0-9]{3}.[0-9]{3}.[0-9]{2}"
          />

          <FrameInput
            handleFunction={handleSetFormData}
            mask="(99) 9999.99999"
            label="Telefone*"
            name="nom_fone1"
            type="tel"
            size={49}
          />
        </Row>

        <Row>
          <FrameInput
            required
            handleFunction={handleSetFormData}
            label="Email*"
            type="email"
            name="nom_email"
            size={49}
          />
          <FrameInput
            required
            handleFunction={handleSetFormData}
            label="Senha**"
            type="password"
            name="nom_senha"
            size={49}
          />
        </Row>

        <Row>
          <ParagphElement marginBottom={2} marginTop={5}>
            *Item obrigatório.
          </ParagphElement>
        </Row>
        <Row>
          <ParagphElement marginBottom={30} marginTop={2}>
            **Sua senha deve conter no mínimo um caractere especial, uma letra maíuscula, um número e no mínimo 8
            caracteres.
          </ParagphElement>
        </Row>

        <Row>
          <ReCAPTCHA
            ref={(r) => setCaptchaRef(r)}
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA || ''}
            onChange={onChangeRecaptcha}
          />
        </Row>

        <Row>
          <FrameButton onClick={handleCancel} size={49}>
            Cancelar
          </FrameButton>
          <FrameButton type="submit" size={49}>
            Cadastrar
          </FrameButton>
        </Row>
      </form>
    </FrameData>
  );
};

export default IframeAccount;
