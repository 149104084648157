import { ParagphElement, Title } from './styles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import iframeContext, { IIframeContext } from '../../contexts/IframeContext';

import { FrameButton } from '../../components/FrameButton';
import { FrameData } from '../../components/FrameData';
import { FrameInput } from '../../components/FrameInput';
import { FrameSelect } from '../../components/FrameSelect';
import { ITiposOcorrencia } from '../../dtos/TipoOcorrencia';
import Loading from '../../components/Loading';
import { RouteComponentProps } from 'react-router-dom';
import { Row } from '../../components/Row';
import { createOcorrencia } from '../../api/services/OcorrenciaPublica';
import { getTiposOcorrencia } from '../../api/services/TipoOcorrencia';
import { prefix } from '../../router';
import { useToast } from '../../hooks/toast';
import useLocalStorage from '../../hooks/useLocalStorage';
import { IReclamante } from '../../dtos/Reclamante';

interface IFormData {
  dsc_observacao?: string;
  seq_tipo_ocorrencia?: number;
}

const IframeSend = ({ history }: RouteComponentProps) => {
  const [tiposOcorrencia, setTiposOcorrencia] = useState<ITiposOcorrencia[]>([]);
  const [dadosReclamante] = useLocalStorage<IReclamante>('reclamante', {});
  const { setIframeState, iframeState } = useContext<IIframeContext>(iframeContext);
  const [formData, setFormData] = useState<IFormData>({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const toast = useToast();
  let inProgress = false;

  const loadTiposOcorrencia = useCallback(async () => {
    const response = await getTiposOcorrencia();
    setLoadingPage(false);

    if (response.err) {
      toast.addToast({ type: 'warn', description: response.err });
    }

    setTiposOcorrencia(response.resp);
  }, []);

  function handleRedirectToAddress() {
    history.push(`${prefix}/generate/address`);
  }

  function handleSetDataInIframeState(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setFormData((oldValue) => ({
      ...oldValue,
      [name]: value,
    }));
  }

  async function handleCreateOcorrencia(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!formData.seq_tipo_ocorrencia) {
      toast.addToast({
        type: 'warn',
        description: 'Nenhum tipo de ocorrencia foi selecionado!',
      });
      return;
    }

    if (inProgress) return;

    inProgress = true;
    let dados;

    if (iframeState && iframeState.dadosCadastroOcorrencia) {
      dados = { ...iframeState.dadosCadastroOcorrencia };

      if (dados && dados.ocorrenciaPontoServico) {
        dados.ocorrenciaPontoServico.cod_tipo_ocorrencia = formData.seq_tipo_ocorrencia;
        dados.ocorrenciaPontoServico.dsc_observacao = formData.dsc_observacao;
      }
    }

    setLoadingSubmit(true);
    const response = await createOcorrencia({ ...dados, cod_reclamante: dadosReclamante.seq_reclamante });
    inProgress = false;
    setLoadingSubmit(false);

    if (response.resp.err) {
      toast.addToast({ type: 'warn', description: response.resp.err });
    }

    toast.addToast({
      type: 'success',
      description: 'Ocorrencia cadastrada com sucesso!',
    });

    if (setIframeState) {
      setIframeState((oldValue) => ({
        ...oldValue,
        dadosCadastroOcorrencia: {},
      }));
    }

    history.push(`${prefix}/home`);
  }

  useEffect(() => {
    if (!iframeState || (iframeState && !iframeState.dadosCadastroOcorrencia)) {
      history.push(`${prefix}/home`);
    }

    loadTiposOcorrencia();

    return () => setTiposOcorrencia([]);
  }, [iframeState, loadTiposOcorrencia]);

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <FrameData>
      <form onSubmit={handleCreateOcorrencia}>
        <Row>
          <Title>Geração de Ocorrência</Title>
        </Row>
        <Row>
          <ParagphElement marginBottom={30} marginTop={5}>
            Para finalizar, informe os dados finais necessários.
          </ParagphElement>
        </Row>
        <Row>
          <FrameInput
            handleFunction={handleSetDataInIframeState}
            label="Observação"
            required
            name="dsc_observacao"
            size={100}
          />
        </Row>
        <Row>
          <FrameSelect
            handleFunction={handleSetDataInIframeState}
            value={formData.seq_tipo_ocorrencia}
            label="Tipo de Ocorrência"
            name="seq_tipo_ocorrencia"
            size={100}
          >
            {tiposOcorrencia &&
              tiposOcorrencia.map((tipoOcorrencia) => (
                <option key={tipoOcorrencia.seq_tipo_ocorrencia} value={tipoOcorrencia.seq_tipo_ocorrencia}>
                  {tipoOcorrencia.nom_descricao}
                </option>
              ))}
          </FrameSelect>
        </Row>
        <Row>
          <FrameButton onClick={handleRedirectToAddress} size={49}>
            Cancelar
          </FrameButton>
          <FrameButton loading={loadingSubmit} type="submit" size={49}>
            Enviar
          </FrameButton>
        </Row>
      </form>
    </FrameData>
  );
};

export default IframeSend;
