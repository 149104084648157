import { ParagphElement, Title } from './styles';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { newPasswordReclamante, verifyTokenPasswordReclamante } from '../../api/services/Reclamante';

import ChangedPassword from './components/ChangedPassword';
import { FrameButton } from '../../components/FrameButton';
import { FrameData } from '../../components/FrameData';
import { FrameInput } from '../../components/FrameInput';
import { Row } from '../../components/Row';
import { useToast } from '../../hooks/toast';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const IframeResetPassword = ({ history }: RouteComponentProps) => {
  const query = useQuery();
  const toast = useToast();
  const [token, setToken] = useState<string>(query.get('token') || '');
  const [newPassword, setNewPassword] = useState<string>('');
  const [verifyPassword, setVerifyPassword] = useState<string>('');
  const [changed, setChanged] = useState<boolean>(false);

  async function handleNewPassword(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!newPassword) {
      toast.addToast({ type: 'warn', description: 'Email invalido!' });
    }

    const response = await newPasswordReclamante({ token, new_password: newPassword, verify_password: verifyPassword });

    if (response.failed) {
      return toast.addToast({
        type: 'warn',
        description: response.message || 'Ocorreu um erro ao mudar a senha do usuario!',
      });
    }

    setChanged(true);

    toast.addToast({
      type: 'success',
      description: 'A senha foi alterada!',
    });
  }

  useEffect(() => {
    const hash = query.get('token') || '';
    if (!hash || hash === '') {
      history.push('/');
    }

    (async () => {
      const response = await verifyTokenPasswordReclamante(hash);

      if (response.failed) {
        toast.addToast({
          type: 'warn',
          description: response.message || 'Ocorreu um erro com esse token!',
        });

        history.push('/');

        return;
      }

      setToken(hash);
    })();
  }, []);

  if (changed) {
    return <ChangedPassword />;
  }

  return (
    <FrameData>
      <Row>
        <Title>Nova Senha</Title>
      </Row>
      <Row>
        <ParagphElement marginBottom={30} marginTop={5}>
          Informe a nova senha para a conta
        </ParagphElement>
      </Row>
      <form onSubmit={handleNewPassword}>
        <Row>
          <FrameInput
            handleFunction={(event: any) => setNewPassword(event.target.value)}
            label="Nova senha"
            type="password"
            size={100}
            required
          />
        </Row>
        <Row>
          <FrameInput
            handleFunction={(event: any) => setVerifyPassword(event.target.value)}
            label="Repita a senha"
            type="password"
            size={100}
            required
          />
        </Row>
        <Row>
          <FrameButton type="submit" size={49}>
            Enviar
          </FrameButton>
        </Row>
      </form>
    </FrameData>
  );
};

export default IframeResetPassword;
