/* eslint-disable consistent-return */

import { Form, HoverElement, ParagphElement, Title } from './styles';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import { FrameButton } from '../../components/FrameButton';
import { FrameData } from '../../components/FrameData';
import { FrameInput } from '../../components/FrameInput';
import { IReclamante } from '../../dtos/Reclamante';
import { Row } from '../../components/Row';
import { loginReclamante } from '../../api/services/Reclamante';
import { prefix } from '../../router';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useToast } from '../../hooks/toast';
import { verifyParqueExists } from '../../api/services/ParqueService';

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

const IframeLogin = ({ history }: RouteComponentProps) => {
  const [accessToken, setAccessToken] = useLocalStorage<string | undefined>('accessToken', '');
  const [refreshToken, setRefreshToken] = useLocalStorage<string | undefined>('refreshToken', '');
  const [codParque, setCodParqueServico] = useLocalStorage<number>('cod_parque_servico', -1);
  const [parqueIsValid, setParqueIsValid] = useState(true);
  const [, setDadosReclamante] = useLocalStorage<IReclamante | string>('reclamante', '');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [dadosLogin, setDadosLogin] = useState({
    email: '',
    password: '',
  });
  const toast = useToast();
  const query = useQuery();

  function handleSetState(event: React.ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;

    setDadosLogin((oldValue) => ({ ...oldValue, [name]: value }));
  }

  async function handleLogin(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setLoadingSubmit(true);
    const res = await loginReclamante(dadosLogin);
    setLoadingSubmit(false);

    if (res.failed) {
      return toast.addToast({ type: 'error', description: res.message });
    }

    if (!res.data || !res.data.complainant) {
      return toast.addToast({ type: 'error', description: 'Usuário ou senha inválidos!' });
    }

    const { 'x-access-token': accessToken, 'x-refresh-token': refreshToken } = res.headers;

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setDadosReclamante(res.data.complainant);

    toast.addToast({
      type: 'success',
      description: 'Usuário logado com sucesso!',
    });

    history.push(`${prefix}/home`);
  }

  function handleRedirectToCreateUser() {
    history.push(`${prefix}/new-account`);
  }

  function handleRedirectForgotPassword() {
    history.push(`${prefix}/forgot`);
  }

  async function checkParqueIsValid() {
    const codParque = Number(query.get('cod_parque_servico'));

    if (codParque === -1 || !codParque) {
      setParqueIsValid(false);
      return;
    }

    const data = await verifyParqueExists(codParque);

    if (!data || !data.resp || !data.resp.check) {
      setParqueIsValid(false);
    }
  }

  useEffect(() => {
    checkParqueIsValid();
  }, []);

  useEffect(() => {
    const codParqueServico = query.get('cod_parque_servico');

    if (codParqueServico) {
      setCodParqueServico(Number(codParqueServico));
    } else if (codParque) {
      window.location.search = `cod_parque_servico=${codParque}`;
    }

    if (accessToken && refreshToken) {
      history.push(`${prefix}/home`);
    }
  }, [history, accessToken, refreshToken]);

  return codParque && codParque !== -1 && parqueIsValid ? (
    <FrameData>
      <Row>
        <Title>Bem-vindo(a)</Title>
      </Row>
      <Row>
        <Title weight="bold">ao portal do usuário de Iluminação Pública</Title>
      </Row>
      <Row>
        <ParagphElement marginBottom={30} marginTop={5}>
          Para poder solicitar manutenções nas lâmpadas de sua cidade, é necessário informar E-mail e Senha.
        </ParagphElement>
      </Row>
      <Form id="form-login" onSubmit={handleLogin}>
        <Row>
          <FrameInput handleFunction={handleSetState} name="email" label="Email" type="email" size={49} required />
          <FrameInput
            handleFunction={handleSetState}
            name="password"
            label="Senha"
            type="password"
            size={49}
            required
          />
        </Row>
      </Form>
      <HoverElement onClick={handleRedirectForgotPassword}>Esqueceu a senha?</HoverElement>
      <Row>
        <FrameButton size={49} onClick={handleRedirectToCreateUser}>
          Criar uma conta
        </FrameButton>
        <FrameButton loading={loadingSubmit} type="submit" form="form-login" size={49}>
          Entrar
        </FrameButton>
      </Row>
    </FrameData>
  ) : (
    <p>Parque de serviço inválido.</p>
  );
};

export default IframeLogin;
