import { IApiResponse, IFixResponse, api, apiExternal } from '../index';

export async function getParqueServicoById(id: number | string): Promise<IFixResponse> {
  const res: IApiResponse = await api.get(`/reclamante/sgi/parqueservico/${id}`);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}

export async function getMunicipioByParqueServico(codParque: number | string): Promise<IFixResponse> {
  const res: IApiResponse = await apiExternal.get(`/external/municipio/parqueservico/${codParque}`);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}

export async function verifyParqueExists(codParque: number | string): Promise<IFixResponse> {
  const res: IApiResponse = await apiExternal.get(`/external/parqueservico/verificar/${codParque}`);

  if (res.failed) {
    if (res.message) {
      return { err: res.message, data: null };
    }
    return { err: res.err, data: null };
  }

  const { data } = res;
  return { err: null, resp: data };
}
