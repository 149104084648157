import styled from 'styled-components';

export interface IContainer {
  size?: number;
}

export interface IRoundedButton {
  loadingBtn?: boolean | any;
}

export const Container = styled.div<IContainer>`
  width: ${(props) => (props.size ? props.size : '13')}%;
`;

export const RoundedButton = styled.button<IRoundedButton>`
  margin-top: 30px;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffa200;

  cursor: ${(props) => (props.loadingBtn ? 'not-allowed' : 'pointer')};

  &:focus {
    outline: unset !important;
  }

  &:disabled {
    background-color: #a8a8a8;
    cursor: not-allowed;
  }
`;

export const Loader = styled.div`
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid transparent;
  width: 22px;
  height: 22px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
