import style from 'styled-components';

interface IContainer {
  size?: number;
  margin?: string;
}

export const Container = style.div<IContainer>`
  color: #ffffff;
  width: ${(props) => props.size || 13}%;
  margin: ${(props) => props.margin || '0px'}%;
`;

export const Label = style.label`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #6e6e6e;
  font-weight: normal;
  display: inline-block;

  margin-top: 5px;
  margin-bottom: 5px;
`;

export const ContainerSelect = style.div`
  padding-left: 0px;
  margin-bottom: unset;
  background-color #f5f5f5;
  color: #a8a8a8;
  border-radius: 10px;
  margin-top: unset;
  display: flex;
  height: 40px;
  padding-right: 10px;

  & select {
    background-color: transparent;
    color: #6e6e6e;
    width: 100%;
    padding: 10px;
    border: none;
  }
  & select:focus {
    outline: none;
  }
  & select option {
    color: #6e6e6e;
    background-color: #f5f5f5;
  }
`;
