import styled from 'styled-components';

export interface ITableData {
  size?: number;
}

export interface ITableRow {
  column?: number;
  body?: number;
  first?: number;
  last?: number;
}

export const TableWrapper = styled.div<ITableData>`
  padding-top: 15px;
  padding-bottom: 10px;
  width: 100vw;
  height: ${(props) => props.size || 40}vh;

  @media (max-width: 1599px) {
    height: ${(props) => (props.size ? props.size - 10 : 30)}vh;
    max-height: 34vh;
  }
`;

export const DataTable = styled.div`
  border: 1px solid #eae7e7;
  border-radius: 16px;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const MainTable = styled.table<ITableRow>`
  width: 100%;
  border-spacing: 0 !important;

  & thead {
    display: block !important;
    text-align: left;
    background-color: #f5f5f5;
    padding-right: 14px;
  }
  & tbody {
    overflow-y: scroll !important;
    display: block !important;
    width: 100% !important;
    height: ${(props) => props.body || 33}vh;
  }
  & tbody::-webkit-scrollbar {
    background-color: #ffffff;
    width: 14px;
  }
  & tbody::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
  & tbody::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 14px;
    border: 3px solid #ffffff;
  }
  & tbody::-webkit-scrollbar-button {
    display: none;
  }
  & td,
  th {
    font-family: 'Roboto', sans-serif;
    font-size: 14px !important;
    color: #6e6e6e;
    font-weight: normal;
    padding-top: 10px !important;
    padding-right: 20px !important;
    padding-bottom: 10px !important;
    padding-left: 20px !important;
    width: ${(props) => props.column}%;
  }
  & tr:nth-child(even) {
    background-color: #fafafa;
  }
  & td:first-child,
  th:first-child {
    width: ${(props) => `${props.first}%` || ''};
  }
  & td:last-child,
  th:last-child {
    width: ${(props) => `${props.last}%` || ''};
  }

  @media (max-width: 1599px) {
    & td,
    th {
      font-size: 10px !important;
      padding-top: 5px !important;
      padding-right: 10px !important;
      padding-bottom: 5px !important;
      padding-left: 10px !important;
    }
    & tbody {
      height: ${(props) => (props.body ? -10 : 23)}vh;
    }
  }
`;
