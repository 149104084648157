import React, { useState } from 'react';
import { FrameData } from '../../components/FrameData';
import { FrameButton } from '../../components/FrameButton';
import { FrameInput } from '../../components/FrameInput';
import { Row } from '../../components/Row';
import { RouteComponentProps } from 'react-router-dom';
import { prefix } from '../../router';
import { resetPasswordReclamante } from '../../api/services/Reclamante';
import { useToast } from '../../hooks/toast';
import { Title, ParagphElement } from './styles';

const IframePassword = ({ history }: RouteComponentProps) => {
  const toast = useToast();
  const [email, setEmail] = useState<string>('');

  function handleCancel() {
    history.push(`${prefix}/`);
  }

  async function handleForgotPassword(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!email) {
      toast.addToast({ type: 'warn', description: 'Email invalido!' });
    }
    const response = await resetPasswordReclamante({ email });

    if (response.failed) {
      return toast.addToast({
        type: 'warn',
        description: response.message || 'Ocorreu um erro ao recuperar a senha do usuario!',
      });
    }

    toast.addToast({
      type: 'success',
      description: 'Foi enviado um email de recuperação de senha para sua caixa de entrada!',
    });

    return handleCancel();
  }

  return (
    <FrameData>
      <Row>
        <Title>Recuperar Senha</Title>
      </Row>
      <Row>
        <ParagphElement marginBottom={30} marginTop={5}>
          Informe o email do cadastro, será enviado um link para realizar a recuperação.
        </ParagphElement>
      </Row>
      <form onSubmit={handleForgotPassword}>
        <Row>
          <FrameInput
            handleFunction={(event: any) => setEmail(event.target.value)}
            label="Email"
            type="email"
            size={100}
            required
          />
        </Row>
        <Row>
          <FrameButton onClick={handleCancel} size={49}>
            Cancelar
          </FrameButton>
          <FrameButton type="submit" size={49}>
            Enviar
          </FrameButton>
        </Row>
      </form>
    </FrameData>
  );
};

export default IframePassword;
