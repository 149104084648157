import { AxiosInstance } from 'axios';
import { getItemInStorage } from '../../hooks/useLocalStorage';

export const addTokenOfAllRequests = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getItemInStorage('accessToken');
      if (token && token !== '') {
        config.headers['x-access-token'] = token;
      }

      return config;
    },
    (error) => Promise.reject(error),
  );
};
