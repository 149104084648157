import React from 'react';

import { TableWrapper, DataTable, MainTable, ITableData } from './style';

interface ITable extends ITableData {
  column: number;
  body?: number;
  first?: number;
  last?: number;
  children?: React.ReactNode;
}

export const FrameTable = ({ children, size, column, body, first, last }: ITable) => {
  return (
    <TableWrapper size={size}>
      <DataTable>
        <MainTable first={first} last={last} body={body} column={column}>
          {children}
        </MainTable>
      </DataTable>
    </TableWrapper>
  );
};
